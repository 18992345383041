<template>
    <div>
         <div class="Header"><Header/></div>
          <div class="orderdetail">
    <img :src="shenshu.img"  style="width: 100%; height: 150px;"/>
    <h5>用户名：{{shenshu.username }}</h5>
    <h5>帮手名：{{shenshu.bname}}</h5>
    <h5>预计金额：{{shenshu.money}}</h5>
    <h5>任务地址：{{shenshu.buyAddress}}</h5>

         <h5>备注：{{shenshu.detailAddress}}</h5>
             <h5>开始时间：{{shenshu.orderCreateTime}}</h5>
<h5 v-if="shenshu.type==0">任务类型：代购物品</h5><h5 v-if="shenshu.type==1">任务类型：专业陪伴</h5><h5 v-if="shenshu.type==2">任务类型：宠物代看</h5><h5 v-if="shenshu.type==3">任务类型：其他</h5>
                     <h5>任务名称：{{shenshu.orderName}}</h5>
                         <h5>联系电话：{{shenshu.dh}}</h5>
    <h5>赏金：{{shenshu.price}}</h5>
<h5 v-if="shenshu.orderStatus==0">任务状态：未接单</h5><h5 v-if="shenshu.orderStatus==1">任务状态：正在进行</h5><h5 v-if="shenshu.orderStatus==2">任务状态：已完成</h5><h5 v-if="shenshu.orderStatus==3">任务状态：异常</h5>
         <van-button square type="danger" @click="sc(shenshu.orderid)" text="删除" />
    <van-button square type="primary" @click="bj(shenshu.orderid)" text="编辑" />
  </div>
    </div>
</template>

<script>

import Header from '@/components/Header.vue';
import { ElMessage } from "element-plus";
    export default {
         components: {Header },
        data(){
            return{
                shenshuid:-1,
                shenshu:[],
                
            }
        },
         mounted(){
              console.log("qweq1",this.$route.query.orderid)
            this.shenshuid=this.$route.query.orderid

            this.getShenshuById();
            
          
        },
        methods:{
            getShenshuById(){
                this.axios({
                    method:"get",
                    url:"/api/order/byid",
                    params:{
                        
                        orderid:this.shenshuid,
                    }
                })
                .then(
                (r)=>{                  
                  this.shenshu = r.data.data[0];  
          
          // 首先输出 shenshu 对象  
          console.log("shenshu:", this.shenshu);  
            
          // 检查 shenshu 是否存在（注意：这里应该是检查对象而不是长度，因为 shenshu 是单个对象）  
          if (this.shenshu) {  
              if (this.shenshu.img) {  
                  this.shenshu.img = this.$store.state.apiImg + this.shenshu.img;  
                  console.log("shenshu.img:",this.shenshu.img)
              }  
              console.log("shenshu 对象已获取并可能已修改 img 属性");  
          } else {  
              console.log("没有获取到 shenshu 对象");  
          }  
      })  
      .catch((error) => {  
          console.error("获取 shenshu 对象时出错:", error);  
      });  
            },
            wc(sid,orderid){
              //将申述删除，将order状态设置为：2  已完成
              this.axios({
              method: "post",
              url:"/api/shenshu/delete",
              params:{
                shenshuid:sid,
              }
             
            })
            console.log(orderid);
                  this.gx(orderid);
               
            },
            gx(orderid){
                    this.axios({
                    method:"post",
                    url:"/api/order/update",
                    params:{
                      orderStatus:2,
                      orderid:orderid,
                    }
                  }).then(
                ()=>{ 
                  ElMessage("订单完成！");
                this.$router.push({path:"/adminMain1"})
                }).catch(
                  ()=>{
                    ElMessage("订单完成失败！");
                })
           
            },
              bj(uid){
               this.$router.push({path:"/amuporder",query:{orderid:uid}})
            },
            sc(uid){
               this.axios({
                    method:"post",
                    url:"/api/order/delete",
                    params:{
                      orderid:uid,
                    }
                  }).then(
                ()=>{ 
                  ElMessage("删除完成！");
                this.$router.push({path:"/adminMain1"})
                }).catch(
                  ()=>{
                    ElMessage("删除失败！");
                })
            }
        },
       
    }
</script>

<style lang="scss" scoped>
.orderdetail {
  text-align: center;
  background-color: rgb(248, 248, 243);
  border: 1px solid;
//   border-radius: 20px;
  overflow: hidden;
}
.orderdetail h5{
  margin-top: 10px;
}
.wc{
    margin: 10px;
}   
</style>
<template>
    <div class="header" style="margin: 0;padding: 0;">
      <van-nav-bar title="帮帮" left-text="首页" left-arrow  @click-left="goto()">
  <template #right>
      <img src="../assets/帮帮logo.png" style="" class="ii">
    
  </template>
</van-nav-bar>
    </div>
</template>

<script>

    export default {
  
      mounted() {
this.username = sessionStorage.getItem("name");
    console.log("==============head_username:"+this.username);
          
this.value = sessionStorage.getItem("usertype");
    console.log("==============head_value:"+this.value);
  },
    methods:{
      iif(a){
     console.log("==============a:::::value:"+ a);
      if(a==0)
      return "adminMain";
      if(a==1)
      return "main";
      if(a==2)
      return "bsMain";
   },
goto(){
   this.$router.push({name:this.iif(this.value),params:{username:sessionStorage.getItem("usertype")}})

},
    },
  
    }
</script>

<style lang="scss" scoped>

.header{
  position: relative;
}
.ii{
  position: absolute;
    width: 100%;
    right: 20px;
}
::v-deep    .van-nav-bar__text{
     color: rgb(70, 72, 80);
}
::v-deep   .van-icon-arrow-left::before
{
       color: rgb(70, 72, 80);
}
::v-deep   .van-nav-bar__title{
    color: rgb(70, 72, 80);
    font-size: 20px;
}






</style>
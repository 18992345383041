<template>
    <div>
<Header/>
 <div class="kk">
 <!-- 文件上传 -->
   <el-upload action="/api/upload/img"
        :show-file-list="true"
        :on-success="handleFileUploadSuccess"
        name="tupian"
        ref="aupload"
        :limit="1"
        style="display: inline-block">
    <el-button type="primary" class="ml-5">
        上传图片 
    </el-button>
    
</el-upload>
<el-button type="primary" @click="clear()" style="  margin-left: 20px;" class="ml-5">
   删除图片</el-button>


  <van-field
    v-model="shenshu.shenshu"
    rows="2"
    autosize
    label="申述"
    type="textarea"
    maxlength="50"
    placeholder="请填写您的申述内容"
    show-word-limit
  />




   <van-button class="fb" round type="primary" @click="fb">发布申述</van-button>
      </div>
    </div>
</template>

<script>
import { ElMessage } from "element-plus";
import Header from '@/components/Header.vue';
import { ref } from 'vue';
    export default {
            components: {Header },
            setup(){
    const imgUrl=ref('');
    const handleFileUploadSuccess = (r) =>{
      console.log("qwe",r.data);
      imgUrl.value=r.data;
     
    };
    return{
        handleFileUploadSuccess,
        imgUrl,
    }
  
            },

      data(){
        return{
            shenshu:[],
            orderid:-1,
            order:[],
        }
    },
    mounted(){
      this.orderid=this.$route.query.orderid;
      console.log(this.orderid);
      this.getOrderByid(this.orderid);


    
    },
    methods:{
            clear(){
this.$refs.aupload.clearFiles();
      },
      fb(){
           let url = "/api/shenshu/add";
                  this.axios({
                    method:"post",
                    url:url,
                    params:{
                      shenshu:this.shenshu.shenshu,
                      username:this.order.username,
                      time:this.order.orderCreateTime,
                      bname:this.order.bname,
                      who:this.order.username,
                      orderid:this.orderid,
                      whoStatus:1,
                      img:this.imgUrl,
                    }
                  }).then(
                ()=>{ 
                  ElMessage("发布申述成功！");
                  this.yc();
                    this.$router.push({path:"/bswdfb"})
                }).catch(
                  ()=>{
                    ElMessage("发布申述失败！");
                })
          },
          //异常
          yc(){
               let url = "/api/order/update";
                  this.axios({
                    method:"post",
                    url:url,
                    params:{
                      orderStatus:3,
                      orderid:this.orderid,
                    }
                  })},


            getOrderByid(orderid){
                 this.axios({
                    method:"get",
                    url:"/api/order/byid",
                    params:{
                        orderid:orderid,
                    }
                })
                .then(
                (r)=>{                  
                     this.order=r.data.data[0];
                     console.log("order:",this.order)
                })}
    }
    }
</script>

<style lang="scss" scoped>
.kk{
  text-align: center;
}
.fb{
    margin-top: 10px;
}
</style>
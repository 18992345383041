<template>
    <div>
         <div class="Header"><Header/></div>
          <div class="orderdetail">
    <img :src="shenshu.img"  style="width: 100%; height: 150px;"/>
    <h5>用户名：{{shenshu.username }}</h5>
    <h5>性别：{{shenshu.userSex}}</h5>
    <h5>电话：{{shenshu.userTel}}</h5>
    <h5>电子邮件：{{shenshu.emil}}</h5>
     <h5 v-if="shenshu.status==1">身份：用户</h5><h5 v-if="shenshu.status==2">身份：帮手</h5>
         <van-button square type="danger" @click="sc(shenshu.userID)" text="删除" />
    <van-button square type="primary" @click="bj(shenshu.userID)" text="编辑" />
  </div>
    </div>
</template>

<script>

import Header from '@/components/Header.vue';
import { ElMessage } from "element-plus";
    export default {
         components: {Header },
        data(){
            return{
                shenshuid:-1,
                shenshu:[],
                
            }
        },
         mounted(){
              console.log("qweq",this.$route.query.username)
            this.shenshuid=this.$route.query.username

            this.getShenshuById();
            
          
        },
        methods:{
            getShenshuById(){
                this.axios({
                    method:"get",
                    url:"/api/user/qt",
                    params:{
                        
                        username:this.shenshuid,
                    }
                })
                .then(
                (r)=>{                  
                  this.shenshu = r.data.data[0];  
          
          // 首先输出 shenshu 对象  
          console.log("shenshu:", this.shenshu);  
            
          // 检查 shenshu 是否存在（注意：这里应该是检查对象而不是长度，因为 shenshu 是单个对象）  
          if (this.shenshu) {  
            if (this.shenshu.img) {  
                  this.shenshu.img = this.$store.state.apiImg + this.shenshu.img;  
                  console.log("shenshu.img:",this.shenshu.img)
              }  
              console.log("shenshu 对象已获取并可能已修改 img 属性");  
          } else {  
              console.log("没有获取到 shenshu 对象");  
          }  
      })  
      .catch((error) => {  
          console.error("获取 shenshu 对象时出错:", error);  
      });  
            },
            wc(sid,orderid){
              //将申述删除，将order状态设置为：2  已完成
              this.axios({
              method: "post",
              url:"/api/shenshu/delete",
              params:{
                shenshuid:sid,
              }
             
            })
            console.log(orderid);
                  this.gx(orderid);
               
            },
            gx(orderid){
                    this.axios({
                    method:"post",
                    url:"/api/order/update",
                    params:{
                      orderStatus:2,
                      orderid:orderid,
                    }
                  }).then(
                ()=>{ 
                  ElMessage("订单完成！");
                this.$router.push({path:"/adminMain1"})
                }).catch(
                  ()=>{
                    ElMessage("订单完成失败！");
                })
           
            },
            bj(uid){
               this.$router.push({path:"/amzhgl",query:{userid:uid}})
            },
            sc(uid){
               this.axios({
                    method:"post",
                    url:"/api/user/delete",
                    params:{
                      userID:uid,
                    }
                  }).then(
                ()=>{ 
                  ElMessage("删除完成！");
                   this.$router.push({path:"/amzhgl",query:{userid:uid}})
                }).catch(
                  ()=>{
                    ElMessage("删除失败！");
                })
            }
        },
       
    }
</script>

<style lang="scss" scoped>
.orderdetail {
  text-align: center;
  background-color: rgb(248, 248, 243);
  border: 1px solid;
//   border-radius: 20px;
  overflow: hidden;
}
.orderdetail h5{
  margin-top: 10px;
}
.wc{
    margin: 10px;
}   
</style>
<template>

   
<van-tabbar class="Footer" route v-model="active" safe-area-inset-bottom>
  <van-tabbar-item replace to="/adminMain1" icon="home-o">申述管理</van-tabbar-item>
  <van-tabbar-item replace to="/amyhgl"  icon="manager-o" >用户管理</van-tabbar-item>
  <van-tabbar-item replace to="/ambsgl"  icon="user-o" >帮手管理</van-tabbar-item>
    <van-tabbar-item replace to="/amrwgl"  icon="records" >任务管理</van-tabbar-item>
  <van-tabbar-item replace  to="/amwode"  icon="setting-o" >我的</van-tabbar-item>
</van-tabbar>




</template>

<script>
import { ref } from 'vue';
    export default {
          setup() {
    const active = ref(0);
    return { active };
  },
  data(){
    return{
        username:"",
        value:-1,
    }
  },
 
      mounted() {
     
  this.value=sessionStorage.getItem("usertype");
  // console.log("=============="+ Store.state.username);
  // this.value=Store.state.value;
    console.log("==============value:"+ this.value);
  },
  methods:{
   iif(a){
     console.log("==============a:::::value:"+ a);
      if(a==0)
      return "adminMain";
      if(a==1)
      return "main";
      if(a==2)
      return "bsMain";
   },
shouye(){
   this.$router.push({name:this.iif(this.value),params:{username:sessionStorage.getItem("name")}})

},
  

       
    }
    }
    
</script>

<style lang="scss" scoped>



</style>
<template>
  <div>
<div class="Header"><Header/></div>

<van-search
  v-model="value"
  show-action
  placeholder="请输入您想接的类型"
  @search="getOrderByName()"
>
<template #action>
    <div @click="getOrderByName()">搜索</div>
  </template>
</van-search>
   

<!-- <van-grid :border="false" :column-num="4" square :gutter="10">
  <van-grid-item>
     <img src="../assets/帮帮logo.png"  style="width: 100%;"> 
    <span>代购物品</span>
  </van-grid-item>
  <van-grid-item>
     <img src="../assets/帮帮logo.png"  style="width: 100%;"> 
    <span>专业陪伴</span>
  </van-grid-item>
  <van-grid-item>
     <img src="../assets/帮帮logo.png"  style="width: 100%;"> 
    <span>宠物代看</span>
  </van-grid-item>
  <van-grid-item>
   
    <img src="../assets/帮帮logo.png"  style="width: 100%;">  
    <span>更多></span>
  </van-grid-item>
</van-grid> -->


<van-grid  :column-num="2" square :gutter="30"    style=" --grid-item-content-background-color:#848484;">

  <van-grid-item v-for="v in mlist" :key="v.orderid" @click="detel(v.orderid)" > 

  <span  class="bz">{{v.orderCreateTime}}</span>
  <span  class="bz">{{v.orderName}}</span>
  <span class="bz">{{v.buyAddress}}</span>
  <span class="mn">¥{{v.price}}</span>


  </van-grid-item>

</van-grid>

<div class="pagination-container">  
  <van-pagination
      @click="getOrderByName()"
      v-model="currentPage"
  :total-items="countOrder"
  :show-page-size="3"/>
</div>

<div class="Footer"><BSFooter/></div>

  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import BSFooter from '@/components/BSFooter.vue';
import { ElMessage } from "element-plus";
import { ref } from "vue";
  export default {


    data(){
      return{
        username:"",
          mlist:[],
          value:"",
          orderName:"",
          countOrder:0,
      }
    },

    setup() {
    const currentPage = ref(1);
    return { currentPage };
  },
        mounted() {

  



//         const dqy=(this.currentPage-1)*10;
//       const gs=10;
//   console.log("页数："+this.currentPage);
//     this.axios({
//               method: "get",
//               url:"/api/order/page?pageNum="+dqy+"&pageSize="+gs,
             
//             })
//             .then(
//                 (response)=>{                  
//                     this.mlist = response.data.data;
//                     console.log(this.mlist);
//                 });

                //////////////////////
             


  //             const dqy=(this.currentPage-1)*10;
  //     const gs=10;
  // console.log("页数："+this.currentPage);
  //  this.axios({
  //             method: "get",
  //             url:"/api/order/pagename?pageNum="+dqy+"&pageSize="+gs+"&orderName="+this.value,
             
  //           })
  //           .then(
  //               (response)=>{                  
  //                   this.mlist = response.data.data;
  //                   console.log(this.mlist);
  //               });
    

    this.getOrderByName();

    this.axios({
      method:"get",
      url:"/api/order/countOrderByOrderStatus",
      params:{orderStatus:0},
    })
    .then((response)=>{
      console.log(response.data.data);
      this.countOrder=response.data.data;
      console.log(this.countOrder);
    })
          
    
  },

computed:{

        },

  methods:{


   
    getOrderByName(){
                    const dqy=(this.currentPage-1)*10;
      const gs=10;
  console.log("页数："+this.currentPage);
   this.axios({
              method: "get",
              url:"/api/order/bspagename",
                params:{
                    pageNum:dqy,
                    pageSize:gs,
                    orderName:this.value,    
                    orderStatus:0,          }
            })
            .then(
                (response)=>{                  
                    this.mlist = response.data.data;
                    console.log(this.mlist);
                      if(this.mlist[0]==undefined){ 
                    ElMessage("没有这么多任务啦！！");

      }
                });
    },
        


      // zj(u){
      //               console.log(u);
      //                this.axios({
      //         method: "get",
      //         url:"user/qt/"+u,
      //         data:{username:u},
      //       })
      //       .then(
      //           (response)=>{                  
      //               this.m = response.data.data;
      //               console.log(this.m);
      //           })
      //           },


      detel(id){
           this.$router.push({name:"BSMainDetel",params:{orderid:id}})

},

  },

    components: { BSFooter,Header },
  }
</script>

<style lang="scss" scoped>

img{
  border-radius: 9px;
}
.bz{
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-align: center;
}
.mn{
  color: rgb(236, 93, 93);

}
::v-deep   .van-grid-item__content--surround{
       background-color: rgb(243, 244, 248);
}

.pagination-container {  
  position: fixed;  
  bottom: 50px;  
  left: 0;  
  width: 100%;  
  background-color: #fff; 
  text-align: center; 
  z-index: 9999; 
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1); 

}


</style>
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/LoginView.vue'
import WD from '../views/WDView.vue'
import BSWD from '../views/bs/BSWDView.vue'
import FB from '../views/FaBuView.vue'
import ZC from '../views/ZCView.vue'
import AD from '../views/admin/AdminMainView.vue'
import BS from '../views/bs/BSMainView.vue'
import OD from '../views/MainOrderDetel.vue'
import BSOD from '../views/bs/BSMainOrderDetel.vue'//bs
import AMOD from '../views/admin/AdminMainDetelView.vue'//bs
import SZ from '../views/ShezhiView.vue'
import WDFB from '../views/WDFBView.vue'
import BSWDFB from '../views/bs/BSWDFBView.vue'
import UPOrder from '../views/UpOrderView.vue'
import YHSS from '../views/YHShenshuView.vue'
import BSSS from '../views/bs/BSShenshuView.vue'
import ZHGL from '../views/ZHGLView.vue'
import QB from '../views/QBView.vue'
import BSQB from '../views/bs/BSQBView.vue'
import SSGL from '../views/admin/AdminMainView.vue'
import YHGL from '../views/admin/AMYHGLView.vue'
import BSGL from '../views/admin/AMBSGLView.vue'
import AMWD from '../views/admin/AMWDView.vue'
import RWGL from '../views/admin/AdminRWGLView.vue'
import AMCK from '../views/admin/AMCKUserDetelView.vue'
import AMCKOD from '../views/admin/AMCKOrderDetelView.vue'
import AMZHGL from '../views/admin/AMBJView.vue'
import AMUPOrder from '../views/admin/AMUpOrderView.vue'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/main:username',
    name: 'main',
    meta:{
      yn:true,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/MainView.vue'),
  },
  {
    path: '/wode',
    name: 'wode',
    meta:{
      yn:true,
    },
    component:WD
  },
  {
    path: '/bswode',
    name: 'bswode',
    meta:{
      yn:true,
    },
    component:BSWD
  },
  {
    path: '/shezhi',
    name: 'shezhi',
    meta:{
      yn:true,
    },
    component:SZ
  },
  {
    path: '/fabu',
    name: 'fabu',
    component:FB
  },
  {
  //  path: '/zc:username',
  path: '/zc',
    name: 'zc',
    component:ZC
  },
  {
    path: '/adminMain:username',
    name: 'adminMain',
    meta:{
      yn:true,
    },
    component:AD
  },  
  {
    path: '/bsMain:username',
    name: 'bsMain',
    meta:{
      yn:true,
    },
    component:BS
  },
  {
    path: '/MainDetel:orderid',
    name: 'MainDetel',
    meta:{
      yn:true,
    },
    component:OD
  },
  {
    path: '/BSMainDetel:orderid',
    name: 'BSMainDetel',
    meta:{
      yn:true,
    },
    component:BSOD
  },
  {
    path: '/AdminMainDetel:shenshuid',
    name: 'AdminMainDetel',
    meta:{
      yn:true,
    },
    component:AMOD
  },

  {
    path: '/wdfb',
    name: 'wdfb',
    meta:{
      yn:true,
    },
    component:WDFB
  },
  {
    path: '/bswdfb',
    name: 'bswdfb',
    meta:{
      yn:true,
    },
    component:BSWDFB
  },
  {
    path: '/uporder',
    name: 'uporder',
    meta:{
      yn:true,
    },
    component:UPOrder
  },
  {
    path: '/amuporder',
    name: 'amuporder',
    meta:{
      yn:true,
    },
    component:AMUPOrder
  },
  {
    path: '/yhss',
    name: 'yhss',
    meta:{
      yn:true,
    },
    component:YHSS
  },
  {
    path: '/bsss',
    name: 'bsss',
    meta:{
      yn:true,
    },
    component:BSSS
  },
  {
    path: '/zhgl',
    name: 'zhgl',
    meta:{
      yn:true,
    },
    component:ZHGL
  },
  {
    path: '/qb',
    name: 'qb',
    meta:{
      yn:true,
    },
    component:QB
  },
  {
    path: '/bsqb',
    name: 'bsqb',
    meta:{
      yn:true,
    },
    component:BSQB
  },
  //管理员
  {
    path: '/amssgl',
    name: 'amssgl',
    meta:{
      yn:true,
    },
    component:SSGL
  },
  {
    path: '/ambsgl',
    name: 'ambsgl',
    meta:{
      yn:true,
    },
    component:BSGL
  },
  {
    path: '/amyhgl',
    name: 'amyhgl',
    meta:{
      yn:true,
    },
    component:YHGL
  },
  {
    path: '/amwode',
    name: 'amwode',
    meta:{
      yn:true,
    },
    component:AMWD
  },
  {
    path: '/amrwgl',
    name: 'amrwgl',
    meta:{
      yn:true,
    },
    component:RWGL
  },
  {
    path: '/AMCKUserDetel',
    name: 'AMCKUserDetel',
    meta:{
      yn:true,
    },
    component:AMCK
  },
  {
    path: '/AMCKOrderDetel',
    name: 'AMCKOrderDetel',
    meta:{
      yn:true,
    },
    component:AMCKOD
  },
  {
    path: '/amzhgl',
    name: 'amzhgl',
    meta:{
      yn:true,
    },
    component:AMZHGL
  },
  
  
  


]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to,from,next) => {
  // 这里就直接可以通过访问meta下的isAuthenticated
  // 判断当前路由是否需要进行权限控制
  if(to.meta.yn) {
    if(sessionStorage.getItem('token')) {
          next();
    }else {
          alert("请先登陆");
          next("/")
    }
  }else {
    next();
  }
})

export default router

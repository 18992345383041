<template>
    <div>
        <div class="Header"><Header/></div>
        <ul class="list">
            <li @click="gl()"> <h2>账号管理</h2>     <h4>></h4></li>
            <li @click="gy()"> <h2>关于帮帮</h2>     <h4>></h4></li>
                <li><h2><van-cell title="赞助我们" is-link @click="showPopup" />
<van-popup v-model:show="show" safe-area-inset-bottom:true  position="bottom" >
    <img src="../assets/收款码.jpg" alt=""></van-popup></h2></li>
            <li @click="tuichu()"> <h2>退出登陆</h2>     </li>
        </ul>
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import { ref } from 'vue';
import { showToast } from 'vant';
import { showDialog } from 'vant';
    export default {
        setup() {
    const show = ref(false);
    const showPopup = () => {
      show.value = true;
    };
    return {
      show,
      showPopup,
    };
  },
           mounted(){
   console.log("=============="+sessionStorage.getItem("name") );
this.username = sessionStorage.getItem("name");
    console.log("=============="+this.username);
        },
        methods:{
     tuichu(){
 this.$router.push({ path:"/"});
    window.sessionStorage.clear();
    this.axios({
        method:"get",
        url:"/api/user/logout",
    });
 showToast('退出登陆');
    },
     gl(){
 this.$router.push({ path: "/zhgl"});
    },
       gy(){
                    showDialog({
                     message: "偶然的一次机会，我接触到了社会上有这样的一部分人，例如宝妈、大学生、残疾人、下岗工人、暂时没找的工作的社会人士等等，他们由于自身或者是环境等等的原因，导致无法进行全日制的工作，但是他们本身又具备着一定的能力，可以胜任一些工作，但是如果正常找工作的渠道上，他们很难获取到只工作一两个小时，又或者是可以与自己的生活一起进行的工作，但是现实生活中又有很多人需要这种短期的帮助，需要帮助的人如果仅仅依靠朋友介绍或者是自己去找，就会浪费很多时间，并且得到的也不一定是最好的帮助，因此，在帮助与被帮助两者之间构建一个信息平台，在保障自己的权益的同时又能有更大的信息量，以获取最高性价比的帮助。",
                     theme: 'round-button',
})
    },
        },
          components: {Header},
    }
</script>

<style lang="scss" scoped>
.list{
    position: relative;
}
.list li{
    display: flex;
    margin-left: 20px;
}
.list h4{
    position: absolute;
    right: 30px;
    margin-top: 30px;
}
::v-deep   .van-cell__title{
    font-size: 24px;
    margin-left: -16px;
    color: red;
}
// ::v-deep   .van-popup--bottom{
//      position: relative;
// }

::v-deep   .van-popup--bottom img{
width: 100%;
}
::v-deep   .van-icon-arrow{
       color: red;
}
</style>
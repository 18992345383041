<template>
  <div class="kk">
    <h2>欢迎加入帮帮</h2>
<van-form @submit="onSubmit">
  <van-cell-group inset>
      <!-- 文件上传 -->
   <el-upload action="/api/upload/img"
        :show-file-list="true"
        :on-success="handleFileUploadSuccess"
        name="tupian"
        ref="aupload"
        :limit="1"
        style="display: inline-block">
    <el-button type="primary" class="ml-5">
        上传头像 
    </el-button>
    
</el-upload>
<el-button type="primary" @click="clear()" style="  margin-left: 20px;" class="ml-5">
   删除头像</el-button>






    <van-field 
      v-model="user.username"
      name="用户名"
 
      placeholder="用户名"
      :rules="[{ required: true, message: '请填写用户名' }]"
    />



    
    <van-field style="  margin-top: 15px;"
      v-model="user.password"
      type="password"
      name="密码"

      placeholder="密码"
      :rules="[{ required: true, message: '请填写密码' }]"
    />
     <van-field style="  margin-top: 15px;"
      v-model="user.emil"
      name="邮箱"
      placeholder="邮箱"
      :rules="[{ required: true, message: '请填写邮箱' }]"
    />
  </van-cell-group>


 <van-field
  style="  margin: 0px 46px;width: 80%;"
  v-model="fieldText"
  is-link
  readonly
  placeholder="选择身份"
  @click="showPicker = true"
/>
<van-popup v-model:show="showPicker" round position="bottom">
  <van-picker
    :columns="columns"
    @cancel="showPicker = false"
    @confirm="onConfirm"
  />
</van-popup>


<div style="margin: 16px;">
    
    <van-button @click="fh"
    color="#7232dd" >
      返回
    </van-button>

    <van-button  @click="zc" color="green"  style="margin-left: 20px;">
      注册
    </van-button>
  
  </div>




</van-form>

  </div>
</template>

<script>
import { ref } from 'vue';
import { ElMessage } from "element-plus";
import md5 from 'js-md5'
  export default {
     setup() {
    const columns = [
      { text: '用户', value: 1},
      { text: '帮手', value: 2 },
      // { text: '管理员', value: 0},
     
    ];


    const imgUrl=ref('');
    const handleFileUploadSuccess = (r) =>{
      console.log("qwe",r.data);
      imgUrl.value=r.data;
     
    };
  

    const fieldValue = ref('');
    const showPicker = ref(false);
    const fieldText = ref('');
    const onConfirm = ({ selectedOptions }) => {
      showPicker.value = false;
      fieldValue.value = selectedOptions[0].value;
      fieldText.value = selectedOptions[0].text;
    };

    return {
      columns,
      onConfirm,
      fieldValue,
      showPicker,
      fieldText,
      handleFileUploadSuccess,
      imgUrl,
    };
  },
       data() {
    return {
      user:{username: "", password: "",emil:"",},
      id:[],
      value:"",
    };
    
  },
    methods:{
         fh(){
        this.$router.push({path:"/"})
      },
      clear(){
this.$refs.aupload.clearFiles();
      },
      zc(){
         
  let url = "/api/user/register";
       let url1 = "/api/money/add";
         if (this.user.password!=""&&this.user.password!=null) {
                      this.user.password=md5(this.user.password)
                    }
                  this.axios({
                  
                    method:"post",
                    url:url,
                    params:{
                      username:this.user.username,
                      password:this.user.password,
                      emil:this.user.emil,
                      status:this.fieldValue,
                      img:this.imgUrl,
                      // uploadFile:this.file,
                    },
            
                  }).then(
                ()=>{                  
                            
                          this.axios({
               
                    method:"post",
                    url:url1,
                    params:{
                    username:this.user.username,
                           }
                      })
                     ElMessage("注册成功！")
                             this.$router.push({path:"/"})
                             })                
                .catch(
                  ()=>{
                     ElMessage("注册失败！")  
                  }
                )
     
          


         //
      },
    },
    mounted(){
     // console.log(this.$route.params.username)
     
     
    }
  
  }
</script>

<style lang="scss" scoped>
.kk{
  text-align: center;

}
::v-deep  .van-field__control{
   text-align: center;
 
}
</style>
<template>
    <div>
<div class="Header"><Header/></div>
   <div class="top">
         <!-- <img :src="this.imgurl"> -->
            <img :src= imgurl>
       <div class="wen"  v-for="j in user" :key="j">    
           <h2>{{j.username}}</h2>
       </div>
   </div>
   <div class="center">
     <ul>
         <li  v-for="j in money" :key="j" @click="qb()" > <van-icon name="credit-pay" /> <h2>钱包</h2> <h4 style="color: red;">¥{{j.money}}</h4> </li>
          <li @click="shezhi()"> <van-icon name="setting-o" />  <h2>设置</h2> <h4>></h4> </li>
     </ul>
   </div>
    
        <AMFooter/>
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import AMFooter from '@/components/AMFooter.vue';

  export default {
        components:{
            AMFooter,Header
        },
        data(){
            return{
                 user:[], 
                 imgurl:"",
                 money:[],
            value:"",
            };
        },
        mounted(){
           console.log("=============="+sessionStorage.getItem("name") );
this.username = sessionStorage.getItem("name");
    console.log("=============="+this.username);
          this.getuserByName(this.username);
          this.getMoneyByUsername();
  console.log(this.imgurl+this.user.img)
        },
        methods:{           
          getuserByName(username) {
          let url = "/api/user/qt";
          this.axios({
            method:"get",
            url:url,
            params:{
              username:username
            }
          })
          .then((res) => {
          this.user = res.data.data;
          this.imgurl=this.$store.state.apiImg+this.user[0].img;
          console.log("imgurl:",this.imgurl);
          console.log("user:",this.user[0].userID);
          sessionStorage.setItem("userid", this.user[0].userID);
      });
    },
    getMoneyByUsername(){
       let url = "/api/money/qt";
          this.axios({
            method:"get",
            url:url,
            params:{
              username:this.username
            }
          })
          .then((res) => {
          this.money= res.data.data;
          console.log("money:",this.money);
      });
    },
     
    qb(){
 this.$router.push({ path: "/bsqb"});
    },
 
    shezhi(){
 this.$router.push({ path: "/shezhi"});
    },
        }
    }
</script>

<style lang="scss" scoped>
.top{
    display: flex;
    background-color: rgb(251, 248, 248);
    
}
.van-icon::before{
 font-size: 30px;

}
.top img{
width: 66px;
height: 66px;
border-radius: 50%;
overflow: hidden;
margin: 10px 30px;
}
.wen{
margin: 10px 0;
  overflow:hidden;
  color: rgb(94, 95, 96);
}

.center{
  margin-top: 30px;
}
.center ul li{
  display: flex;
  margin-top: 30px;
  margin-left: 30px;
  position: relative;
}

.center ul li h2{
  padding: 0;
  margin: 0;
  margin-left: 20px;
  margin-top: -3px;
  color: rgb(82, 78, 78);
}
.center ul li h4{
  padding: 0;
  margin: 0;
  position:absolute;
  right:20px;
  top: 6px;
  color: rgb(119, 105, 105);
}
</style>
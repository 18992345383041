<template>
    <div>
        <Header/>
 <div class="kk">
 <!-- 文件上传 -->
   <el-upload action="/api/upload/img"
        :show-file-list="true"
        :on-success="handleFileUploadSuccess"
        name="tupian"
        ref="aupload"
        :limit="1"
        style="display: inline-block">
    <el-button type="primary" class="ml-5">
        上传图片 
    </el-button>
    
</el-upload>
<el-button type="primary" @click="clear()" style="  margin-left: 20px;" class="ml-5">
   删除图片</el-button>
   <van-field  v-model="user.username" placeholder="请输入您需要修改的用户名" label="用户名：" />
      <van-field  v-model="user.password" placeholder="请输入您需要修改的密码" label="密码：" />
         <van-field  v-model="user.userSex" placeholder="请输入您需要修改的性别" label="性别：" />
            <van-field  v-model="user.userTel" placeholder="请输入您需要修改的电话" label="电话：" />
               <van-field  v-model="user.emil" placeholder="请输入您需要修改的邮箱" label="邮箱：" />




                  <van-button round type="primary" @click="gx">修改</van-button>
    </div>
       </div>
</template>

<script>
import { ref } from 'vue';
import { ElMessage } from "element-plus";
import Header from '@/components/Header.vue';
import md5 from 'js-md5'
    export default {
         components: { Header },
         setup(){
             const imgUrl=ref('');
    const handleFileUploadSuccess = (r) =>{
      console.log("qwe",r.data);
      imgUrl.value=r.data;
     
    };
    return {
      handleFileUploadSuccess,
      imgUrl,
    };
         },



         data(){
            return{
                user:[],
                userID:-1,
        
            }
         },
         mounted(){
                     console.log("qweq",this.$route.query.userid)
            this.userID=this.$route.query.userid
         },
              methods:{
           clear(){
this.$refs.aupload.clearFiles();
      },
          gx(){
            if(this.user.password!=''&&this.user.password!=null)
            this.user.password=md5(this.user.password)
           let url = "/api/user/update";
                  this.axios({
                    method:"post",
                    url:url,
                    params:{
                        username:this.user.username,
                        password:this.user.password,
                        userSex:this.user.userSex,
                        userTel:this.user.userTel,
                        emli:this.user.emli,
                        img:this.imgUrl,
                        userID:this.userID,

                    }
                  }).then(
                ()=>{ 
                  ElMessage("修改成功！");
                    this.$router.push({path:"/adminMain1"})
                }).catch(
                  ()=>{
                    ElMessage("修改失败！");
                })
          },
        }
        
    }
</script>

<style lang="scss" scoped>
.kk{
  text-align: center;
  

}
</style>
<template>
  <nav>
    <!-- <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
    <router-link to="/wd">我的</router-link> -->

   
  </nav>
  <router-view/>
</template>

<style lang="scss">
#app {
.Footer{
 margin-top: 50px;
  text-align: center;
}  


}

nav {


}
</style>

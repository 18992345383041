import { createStore } from 'vuex'

export default createStore({
    // stage是数据，不提供外部直接访问
  state: {
    username:"",
    token:"",
    value:"",
    apiImg:"https://springboot.huangwanyv.top/bang/img/",
  },
    // 获取状态中的属性值
  getters: {
    token(state){
      return state.token;
    },
    apiImg(state){
      return state.apiImg;
    }
  },
    // 操作state，类似Java类的setter
  mutations: {
    setToken (state,token){
      state.token = token;//将外部传进来的token，放到公共内存中
      sessionStorage.token = token;//临时会话存储
    },
    del_token(state) {

      state.token = ''
      
      sessionStorage.removeItem('token')//移除
      
      }
  },
  actions: {
  },
  modules: {
  }
})

<template>
  <div class="kk">
    <h2>帮帮——给生活带来帮助</h2>
<van-form @submit="onSubmit">
    <div class="srk">
  <van-cell-group inset >
  
    <van-field   
      v-model="user.username"
      name="用户名"
 
      placeholder="用户名"
      :rules="[{ required: true, message: '请填写用户名' }]"
    />
    <van-field style="  "
      v-model="user.password"
      type="password"
      name="密码"

      placeholder="密码"
      :rules="[{ required: true, message: '请填写密码' }]"
    />
 
  </van-cell-group>

   </div>



 <van-field
  style="  margin: 0px 46px;width: 80%;"
  v-model="fieldText"
  is-link
  readonly
  placeholder="选择身份"
  @click="showPicker = true"
/>
<van-popup v-model:show="showPicker" round position="bottom">
  <van-picker
    :columns="columns"
    @cancel="showPicker = false"
    @confirm="onConfirm"
  />
</van-popup>


  <div style="margin: 16px;">
    <van-button  @click="zc" color="green" >
      注册
    </van-button>
    <van-button @click="dl"
  color="#7232dd" native-type="submit" style="margin-left: 20px;">
      登陆
    </van-button>
  </div>
</van-form>
  </div>
  <h6 class="beian">京ICP备2024326495号</h6>
</template>

<script>
import { ref } from 'vue';
import Store from'@/store/index.ts';
import { ElMessage } from "element-plus";
import md5 from 'js-md5'
  export default {
    setup() {
    const columns = [
      { text: '用户', value: 1},
      { text: '帮手', value: 2 },
      { text: '管理员', value: 0},
     
    ];
    const fieldValue = ref('');
    const fieldText = ref('');
    const showPicker = ref(false);

    const onConfirm = ({ selectedOptions }) => {
      showPicker.value = false;
      fieldValue.value = selectedOptions[0].value;
      fieldText.value = selectedOptions[0].text;
    };

    return {
      columns,
      onConfirm,
      fieldValue,
      showPicker,
      fieldText,
    };
  },
       data() {
    return {
      user:{username: "", password: "",},
      id:"",
      token: "",
      st: "false", //false为不保存登录
      
      value: "",
    };
    
  },
  mounted(){
    console.log("==============好好喝");
    console.log(this.$store.state.apiImg);
  },
    methods:{
      dl(){
       // this.$router.push({path:"/main",query:{username:this.user.username}})
          //console.log(this.value)
            let url = "/api/user/login";
            // let url = "/localhost:9060/bang/user/login";
                  this.axios({
                    method:"get",
                    url:url,
                    params:{
                      username:this.user.username,
                      password:md5(this.user.password),
                      status:this.fieldValue,
                    }
                  }).then(
                (response)=>{                  
                    Store.state.token=response.data.data  ;
                    console.log(Store.state.token);

                    Store.state.value=this.fieldValue ;
                    console.log(Store.state.fieldValue);
                
                // .catch(
                //   ()=>{
                //     Store.state.token=response.data.data  ;
                //     console.log(Store.state.token);
                //   }
                // )
     
            if (Store.state.token == "failed") {
            ElMessage("账号或者密码或身份错误！");
          } else {
            //在点击提交按钮的时候，页面已经显示你修改的数据了，
            //并且所有复用这个组件的地方的数据全都被vue更新了；commit
            //存token
        this.$store.commit("setToken", Store.state.token);


            //localStorage
            // alert(this.$store.state.token)
            sessionStorage.setItem("name", this.user.username); //bjt
            // sessionStorage.setItem("password", this.user.password); //00000000
            if (this.fieldValue == 1) {
              //临时会话存储sessionStorage
              //sessionStorage.getItem(key):获取指定key本地存储的值； 
              //sessionStorage.setItem(key,value):将值存储到key字段； 
              //sessionStorage.removeItem(key):删除指定key本地存储的值；
              // sessionStorage.length是sessionStorage的项目数

              sessionStorage.setItem("usertype", this.fieldValue);//如果输入的名字以及密码正确路由跳转至普通用户首页
              //this.$router.replace("/Main"); 
              this.$router.push({name:"main",params:{username:this.user.username}})
            } else if (this.fieldValue == 0) {
              
              sessionStorage.setItem("usertype", this.fieldValue);
              console.log(this.fieldValue);
                 this.$router.push({name:"adminMain",params:{username:this.user.username}})
             // this.$router.replace("/adminMain"); //如果输入的名字以及密码正确路由跳转至管理首页
            } else if (this.fieldValue == 2) {
              sessionStorage.setItem("usertype", this.fieldValue);
                 this.$router.push({name:"bsMain",params:{username:this.user.username}})
     
            //  this.$router.replace("/bsMain"); //如果输入的名字以及密码正确路由跳转至帮手首页
            }
          }})
      },
      zc(){
        //this.$router.push({name: 'zc', params: {username:this.user.username }})
      //  console.log(this.user.username)
         this.$router.push({path:"/zc"})
       
      },
    }
  
  }
</script>

<style lang="scss" scoped>
.kk{
  text-align: center;
  

}
.item{
  margin-top: 30px;

}
.srk{
  margin-left: 50px;
  margin-right: 50px;

}
::v-deep  .van-field__control{
   text-align: center;
}
.beian{
  position: absolute;  
  bottom: 0; /* 将元素固定在底部 */  
  left: 50%; /* 水平居中（左偏移50%） */  
  transform: translateX(-50%); /* 通过平移自身宽度的50%来实现真正的水平居中 */  
  width: 100%; /* 如果需要，可以设置一个具体的宽度，比如300px，但这里使用100%是为了展示如何居中 */  
  text-align: center; /* 文本居中 */  
  color: #b6b6b6;
}
</style>